import { AfterViewInit, Component, OnInit } from "@angular/core";
import { encodeUserRef } from "@ankaadia/ankaadia-shared";
import { Button } from "primeng-v17/button";
import { DynamicDialogRef } from "primeng-v17/dynamicdialog";
import { environment } from "../../../..//environments/environment";
import { loadFacebookSDK } from "../../../shared/facebook/facebook-sdk";
import { SettingsService } from "../../../shared/services/settings.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-messenger-consent-dialog",
  templateUrl: "./messenger-consent-dialog.component.html",
  imports: [TranslateDirective, Button],
})
export class MessengerConsentDialogComponent implements OnInit, AfterViewInit {
  protected facebookAppId = environment.facebookAppId;
  protected facebookPageId = environment.facebookPageId;
  protected facebookRef: string;

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly settings: SettingsService
  ) {}

  ngAfterViewInit(): void {
    loadFacebookSDK();
  }

  ngOnInit(): void {
    this.facebookRef = encodeUserRef(this.settings.userOrCandidateId, this.settings.organizationId);
  }

  okClicked(): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
