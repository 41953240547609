import { Inject, Injectable, Version } from "@angular/core";
import { APP_VERSION } from "@ankaadia/ankaadia-shared-frontend";
import {
  ConfirmOptInStateGQL,
  Consent,
  GetConsentDataGQL,
  GetConsentGQL,
  GetMessengerOptInStateGQL,
  GetRelevantConsentForUserGQL,
  GiveConsentGQL,
  TcConsentFragment,
} from "@ankaadia/graphql";
import { getBrowserLang } from "@jsverse/transloco";
import { DialogService } from "primeng-v17/dynamicdialog";
import { BehaviorSubject, Observable, ReplaySubject, filter, map } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { MessengerConsentDialogComponent } from "./messenger-consent/messenger-consent-dialog.component";
import { TcConsentDialogComponent } from "./tc-consent/tc-consent-dialog/tc-consent-dialog.component";

@Injectable({ providedIn: "root" })
export class UserConsentService {
  userGaveConsent$ = new BehaviorSubject(false);
  consentCheckProcessed$ = new ReplaySubject<boolean>(1);

  private authUserId: string;
  private userOrCandidateId: string;
  private organizationId: string;
  private isCandidate: boolean;
  private facebookUserId: string;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly dialogService: DialogService,
    private readonly getMessengerConstent: GetMessengerOptInStateGQL,
    private readonly confirmOptIn: ConfirmOptInStateGQL,
    private readonly giveConsent: GiveConsentGQL,
    private readonly consentGet: GetConsentGQL,
    private readonly consentsGet: GetRelevantConsentForUserGQL,
    private readonly consentData: GetConsentDataGQL,
    @Inject(APP_VERSION) private readonly version: Version
  ) {
    this.settingsService.isAuthorized$.pipe(filter((x) => x)).subscribe(() => {
      this.checkAndAskConsent(
        this.settingsService.auth0UserId,
        this.settingsService.userOrCandidateId,
        this.settingsService.organizationId,
        this.settingsService.isCandidate,
        this.settingsService.requireCandidateConsent,
        this.settingsService.requireUserConsent,
        this.settingsService.facebookUserId,
        this.settingsService.hasUserPermission
      );
    });
  }

  getMessengerOptInSate(): Observable<Date | null> {
    return this.getMessengerConstent.fetch().pipe(map((data) => data.data.getMessengerOptInState?.userAskedDate));
  }

  confirmOptInState(): Observable<Date | null> {
    return this.confirmOptIn.mutate().pipe(map((data) => data.data.confirmOptInState?.userAskedDate));
  }

  checkAndAskConsent(
    authUserId: string,
    userOrCandidateId: string,
    organizationId: string,
    isCandidate: boolean,
    requireCandidateConsent: boolean,
    requireUserConsent: boolean,
    facebookUserId: string,
    hasUserPermission: boolean
  ): void {
    this.facebookUserId = facebookUserId;
    this.authUserId = authUserId;
    this.userOrCandidateId = userOrCandidateId;
    this.organizationId = organizationId;
    this.isCandidate = isCandidate;

    if ((isCandidate && requireCandidateConsent) || (!isCandidate && requireUserConsent && hasUserPermission)) {
      this.getConsentData().subscribe((consentData) => {
        if (!consentData) {
          this.showConsentDialog();
        } else {
          this.userGaveConsent$.next(true);
          this.checkAndAskMessagingConsent();
        }
      });
    } else {
      this.checkAndAskMessagingConsent();
    }
  }

  private checkAndAskMessagingConsent(): void {
    if (this.isCandidate) {
      this.getMessengerOptInSate().subscribe((date) => {
        if (this.facebookUserId && date == null) {
          this.consentCheckProcessed$.next(true);
          this.consentCheckProcessed$.complete();
          //this.showMessagingConsentDialog(); Consent is done via facebook at the moment. But this changes maybe in the future
        } else {
          this.consentCheckProcessed$.next(true);
          this.consentCheckProcessed$.complete();
        }
      });
    } else {
      this.consentCheckProcessed$.next(true);
      this.consentCheckProcessed$.complete();
    }
  }

  getConsent(): Observable<TcConsentFragment> {
    return this.consentGet
      .fetch({ organizationId: this.organizationId, auth0UserId: this.authUserId })
      .pipe(map((x) => x.data.getConsent));
  }

  getConsentData(organizationId?: string, authUserId?: string): Observable<Date | null> {
    return this.consentData
      .fetch({ organizationId: organizationId ?? this.organizationId, auth0UserId: authUserId ?? this.authUserId })
      .pipe(map((x) => x?.data.getConsent?.consentDate));
  }

  private getConsents(): Observable<Consent[]> {
    return this.consentsGet
      .fetch({
        input: {
          organizationId: this.organizationId,
          userOrCandidateId: this.userOrCandidateId,
          isCandidate: this.isCandidate,
          browserLanguage: getBrowserLang()?.toUpperCase(),
        },
      })
      .pipe(map((x) => x.data.getRelevantConsentForUser));
  }

  private showConsentDialog(): void {
    this.getConsents().subscribe((consents) => {
      if (consents.length > 0) {
        this.dialogService
          .open(TcConsentDialogComponent, {
            closable: false,
            closeOnEscape: false,
            dismissableMask: false,
            height: "90vh",
            width: "90vw",
            styleClass: "p-dialog-compact",
            modal: true,
            data: consents,
            showHeader: false,
          })
          .onClose.subscribe((x) => this.writeConsent(x));
      } else {
        this.checkAndAskMessagingConsent();
      }
    });
  }

  private writeConsent(agreed: boolean): void {
    if (agreed) {
      const input = {
        candidateOrUserId: this.userOrCandidateId,
        organizationId: this.organizationId,
        auth0UserId: this.authUserId,
        isCandidate: this.isCandidate,
        userAgent: window.navigator.userAgent,
        version: this.version.full,
        browserLanguage: getBrowserLang()?.toUpperCase(),
      };
      this.giveConsent.mutate(input).subscribe(() => {
        this.userGaveConsent$.next(true);
        this.checkAndAskMessagingConsent();
      });
    }
  }

  protected showMessagingConsentDialog(): void {
    this.dialogService
      .open(MessengerConsentDialogComponent, {
        closable: false,
        closeOnEscape: false,
        dismissableMask: false,
        styleClass: "p-dialog-compact",
        modal: true,
        showHeader: false,
      })
      .onClose.subscribe(() => this.writeMessagingConsent());
  }

  writeMessagingConsent(): void {
    this.confirmOptInState().subscribe(() => {
      this.consentCheckProcessed$.next(true);
      this.consentCheckProcessed$.complete();
    });
  }
}
