import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ensure4LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { Consent } from "@ankaadia/graphql";
import { Button } from "primeng-v17/button";
import { CheckboxModule } from "primeng-v17/checkbox";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng-v17/dynamicdialog";
import { FormElementMapModule } from "../../../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { TcConsentViewComponent } from "../tc-consent-view/tc-consent-view.component";

@Component({
  selector: "app-tc-consent-dialog",
  templateUrl: "./tc-consent-dialog.component.html",
  styleUrl: "./tc-consent-dialog.component.scss",
  imports: [
    NgIf,
    TranslateDirective,
    TcConsentViewComponent,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    Button,
  ],
})
export class TcConsentDialogComponent implements OnInit {
  consents: Consent[];
  agreedControl = new FormControl(false);
  viewLanguage: string;
  reRender = true;
  lastIndex = 0;

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.consents = this.config.data;
    this.viewLanguage = ensure4LetterIsoLanguage(this.consents[0]?.language ?? "de-DE");
  }

  okClicked(): void {
    if (this.agreedControl.value) {
      this.dialogRef.close(true);
    }
  }

  onTabChanged(val: { language: string; tabIndex: number }): void {
    this.viewLanguage = ensure4LetterIsoLanguage(val.language ?? "de-DE");
    this.reRender = false;
    setTimeout(() => {
      this.lastIndex = val.tabIndex;
      this.reRender = true;
    }, 10);
  }
}
